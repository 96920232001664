import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BlogFooterCta from "../components/blog/blog-footer-cta"
import BlogSearch from "../components/blog/blog-search"
import GetStartedCta from "../components/blog/get-started-cta"
import BlogMultiplePostPreview from "../components/blog/blog-multiple-post-preview"
import { Link } from "gatsby"

class InsightsPage extends React.Component {
  render() {
    const allAnalystInsights = this.props.data.allContentfulPostsWealthybites
      .nodes

    const title = "Daily market insights from various analysts"
    const description =
      "Expert market analysis, brief yet comprehensive. Investing content uploaded daily"

    return (
      <Layout
        activePage={"insights/"}
        backgroundColor={"#fff"}
        headerBackgroundColor={"#11152E"}
      >
        <SEO
          description={description}
          title={title}
          canonicalUrlPath={"/insights/"}
        />
        <div className="blog-font-family m-0 p-0">
          {/*Directory Header*/}
          <div
            style={{
              backgroundColor: "#11152E",
            }}
          >
            <div className="starry-pattern-background container space-top-2 space-bottom-1 d-flex flex-column gap-4">
              <div>
                <Link className="blog-breadcrump blog-text-gray" to={`/`}>
                  <span>Wealthybites</span>
                </Link>{" "}
                / <span>Insights</span>
              </div>
              <h1 className="blog-directory-title text-white m-0">
                Get the latest information about the investing world
              </h1>
            </div>
          </div>

          <div className="container space-top-2 space-bottom-2 mb-4 editors-pick-container d-flex flex-column">
            <h2 className="text-big blog-text-dark fw-bolder mb-4">
              All Insights
            </h2>
            <BlogMultiplePostPreview
              items={allAnalystInsights}
              className={"mb-11"}
              itemsPerRow={3}
            />
          </div>
        </div>

        {/* astronaut cta */}
        <BlogFooterCta />
      </Layout>
    )
  }
}

export default InsightsPage

export const query = graphql`
  query {
    allContentfulPostsWealthybites(
      sort: { fields: [createdAt], order: DESC }
      filter: { category: { name: { eq: "insights" } } }
    ) {
      nodes {
        slug
        title
        metaDescription
        readingTime
        image {
          file {
            url
          }
        }
        category {
          name
        }
        insightsCategory {
          name
        }
        author {
          name
          image {
            file {
              url
            }
          }
        }
        date
      }
    }
  }
`
